import apiClient from './apis/apiClient'

// Add Global Error Handling
window.addEventListener('error', (event) => {
    apiClient.post('/error', {
        session_attributes_id: window.session_attributes_id,
        url: event.filename,
        line: event.lineno,
        column: event.colno,
        message: event.message,
        stack: event.stack,
    })
})
